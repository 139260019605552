import { UserProvider } from "@auth0/nextjs-auth0/client";
import * as gtag from "lib/gtag";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "styles/global";
import theme from "styles/theme";

function App({ Component, pageProps }: AppProps) {
  const { stylesheetUrl, faviconUrl, browserTabText } = pageProps;

  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <UserProvider>
      <ThemeProvider theme={theme}>
        <Head>
          <title>{browserTabText ?? `Rare Goods - NFT Strategy`}</title>
          <link rel="shortcut icon" href={faviconUrl ?? "/img/icon-512.png"} />
          <link
            rel="apple-touch-icon"
            href={faviconUrl ?? "/img/icon-512.png"}
          />
          <link rel="manifest" href="/manifest.json" />
          {stylesheetUrl && <link rel="stylesheet" href={stylesheetUrl} />}
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          />
          <meta
            name="description"
            content="The World’s First Complete NFT Solution Designed for Major Teams, Brands and Organizations."
          />
        </Head>
        <GlobalStyles />
        <Component {...pageProps} />
      </ThemeProvider>
    </UserProvider>
  );
}

export default App;
